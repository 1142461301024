import request from '@/utils/request'

export default {
  getList(params) {
    return request({
      url: '/v1/pmsList',
      method: 'get',
      params
    })
  },
  getOne(id) {
    return request({
      url: `/v1/pms/${id}`,
      method: 'get'
    })
  },
  update(data) {
    return request({
      url: '/v1/pmsUpdate',
      method: 'post',
      data
    })
  },
  create(data) {
    return request({
      url: '/v1/pmsAdd',
      method: 'post',
      data
    })
  },
  archives(id) {
    return request({
      url: `/v1/pmsArchive/${id}`,
      method: 'get'
    })
  },
  delete(id) {
    return request({
      url: `/v1/pmsDel/${id}`,
      method: 'get'
    })
  },
  submit(data) {
    return request({
      url: '/v1/pmsSubmitReading',
      method: 'post',
      data
    })
  },
  monitorReview(data) {
    return request({
      url: '/v1/pmsMonitorReview  ',
      method: 'post',
      data
    })
  },
  engineerReview(data) {
    return request({
      url: '/v1/pmsEngineerReview',
      method: 'post',
      data
    })
  },
  appendParts(data) {
    return request({
      url: '/v1/workorderAppendParts',
      method: 'post',
      data
    })
  },
  removeParts(id) {
    return request({
      url: `/v1/workorderRemoveParts/${id}`,
      method: 'get'
    })
  },
  editParts(data) {
    return request({
      url: '/v1/workorderEditParts',
      method: 'post',
      data
    })
  },
  appendTask(data) {
    return request({
      url: '/v1/pmsAppendTask',
      method: 'post',
      data
    })
  },
  appendMember(data) {
    return request({
      url: '/v1/workorderAppendMember',
      method: 'post',
      data
    })
  },
  removeMember(id) {
    return request({
      url: `/v1/workorderRemoveMember/${id}`,
      method: 'get'
    })
  },
  removeTask(id) {
    return request({
      url: `/v1/pmsRemoveTask/${id}`,
      method: 'get'
    })
  },
  suspend(data) {
    return request({
      url: `/v1/pmsSuspendApplyFor`,
      method: 'post',
      data
    })
  },
  SuspendReview(data) {
    return request({
      url: '/v1/pmsSuspendReview',
      method: 'post',
      data
    })
  },
  appendTime(data) {
    return request({
      url: '/v1/workorderAppendTime',
      method: 'post',
      data
    })
  },
  handOut(data) {
    return request({
      url: '/v1/pmsHandOut',
      method: 'post',
      data
    })
  },
  removeTime(id) {
    return request({
      url: `/v1/workorderRemoveTime/${id}`,
      method: 'get'
    })
  },
  requestParts(data) {
    return request({
      url: `/v1/workorderRequestParts`,
      method: 'post',
      data
    })
  }
}
